import { useEffect, useRef, useState } from "react";
import {
  chakra,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  SimpleGrid,
  Link,
} from "@chakra-ui/react";
import { useAppStore } from "../lib/store";
import { Category } from "../lib/slices/createCategorySlice";
import ProductCard from "../components/ProductCard";
import { useParams } from "react-router-dom";
import { Link as ReactLink } from "react-router-dom";

import HeartFull from "../img/heart.png";
import { useTranslation } from "react-i18next";
import Check from "../img/check-circle-solid.svg";
import { Product } from "../lib/slices/createProductSlice";
import detectElementOverflow from "detect-element-overflow";
import Arrow from "../img/arrow.png";
import { hasChildren } from "../lib/helpers/products";

export default function Menu() {
  const {
    language,
    products,
    categories,
    cart,
    category,
    productsFromCategory,
    resetCart,
    fetchProductsFromCategory,
    changeCategory,
  } = useAppStore();
  const [orderLength, setOrderLength] = useState(0);
  const [expanded, setExpanded] = useState([0]);
  const [dispLanguage, setDispLanguage] = useState("ro");
  const [showAddNotification, setShowAddNotification] = useState(false);
  const [showRemoveNotification, setShowRemoveNotification] = useState(false);
  const [showScrollIndicator, setShowScrollIndicator] = useState<
    string | boolean
  >(false);

  const [notificationProduct, setNotificationProduct] = useState<
    Partial<Product>
  >({});
  const { cat } = useParams();
  const { i18n, t } = useTranslation();
  const [modal, setModal] = useState(0);
  const child = useRef<HTMLDivElement>(null);
  const parent = useRef<HTMLDivElement>(null);

  const handleModalOpen = (id: number) => {
    setModal(id);
  };

  const updateCategory = (cat: Category) => {
    fetchProductsFromCategory(products, cat);
    changeCategory(cat);
  };

  const handleChange = (index: number) => {
    setExpanded([index]);
  };

  useEffect(() => {
    setDispLanguage(language);
  }, [language]);

  useEffect(() => {
    if (categories) {
      const topCats = categories.filter((cat) => cat?.parent_id == null);
      topCats.map((categ, index) => {
        categories.map((subcat) => {
          if (subcat.parent_id == categ.id) {
            if (category?.id === subcat.id) {
              setExpanded([index]);
            }
          }
        });
        if (categ.parent_id == null && categ.id === category?.id) {
          setExpanded([index]);
        }
      });
    }
  }, [category]);

  useEffect(() => {
    updateCategory(
      categories.find((category) => category.id === parseInt(cat ?? "")) ??
        category!
    );
  }, [cat]);

  useEffect(() => {
    setOrderLength(cart.length);
  }, [cart.length]);

  const resetMenu = () => {
    resetCart();
    changeCategory(categories[0]);
  };

  const detectCollisions = () => {
    const collisions = detectElementOverflow(
      child.current as HTMLElement,
      parent.current as HTMLElement
    );
    if (collisions.collidedBottom && collisions.overflowBottom > 100) {
      setShowScrollIndicator("down");
    } else if (collisions.collidedTop && collisions.overflowTop > 100) {
      setShowScrollIndicator("up");
    } else {
      setShowScrollIndicator(false);
    }
  };

  useEffect(() => {
    detectCollisions();
    function watchScroll() {
      if (parent.current != null) {
        parent.current.addEventListener("scroll", detectCollisions);
      }
    }
    watchScroll();
    return () => {
      if (parent.current != null) {
        parent.current.removeEventListener("scroll", detectCollisions);
      }
    };
  });

  const scrollTo = (to: string) => {
    if (parent.current) {
      if (to === "up") {
        parent.current.scroll({
          top: 0,
          behavior: "smooth",
        });
      } else {
        parent.current.scroll({
          top: parent.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }
  };

  const styles = {
    menu_reset: {
      background: "#FFFFFF",
      boxShadow: "3px 3px 6px 1.5px rgba(0, 0, 0, 0.25)",
      borderRadius: "1.125vw",
      fontWeight: "700",
      fontSize: "0.750vw",
      lineHeight: "0.800vw",
      color: "#FF0000",
      padding: "1vw",
      width: "fit-content",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "0.500vw",
      marginTop: "2.500vw",
      textTransform: "uppercase",
      margin: "3.5vw auto 0",
    },
  };

  return (
    <Flex direction="row" height="100%">
      <Flex
        direction="column"
        width="21.600vw"
        borderRight="6px solid #146C78"
        backgroundColor="#313232"
        color="#fff"
        padding="3.500vw 2.350vw"
        alignItems="flex-start"
        overflowY="scroll"
      >
        <Link
          as={ReactLink}
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="0.5vw"
          fontWeight="600"
          fontSize="0.975vw"
          lineHeight="1.200vw"
          color="#313232"
          background="#FFFFFF"
          borderRadius="1.125vw"
          width="fit-content"
          height="2vw"
          padding="0 0.8vw"
          textTransform="uppercase"
          to="/order"
          state={{
            link: `/menu/${cat}`,
            name: language === "ro" ? category?.name_ro : category?.name_en,
          }}
        >
          <chakra.img
            src={HeartFull}
            alt="favourites"
            height="1.350vw"
            width="1.5vw"
          />
          {t("link_comanda_mea")}
          <chakra.span
            boxShadow="inset 1.5px 1.5px 4.5px rgba(0, 0, 0, 0.25)"
            borderRadius="50%"
            width="1.5vw"
            height="1.5vw"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {orderLength}
          </chakra.span>
        </Link>
        <Accordion allowToggle index={expanded} onChange={handleChange}>
          {categories.map((categ) => {
            if (categ.parent_id == null && hasChildren(categ, categories)) {
              return (
                <AccordionItem key={categ.id} border="none" marginTop="2.500vw">
                  <AccordionButton
                    outline="none"
                    _expanded={{ color: "#146C78" }}
                    color="#FFFFFF"
                    padding={0}
                  >
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontWeight={600}
                      fontSize="1.600vw"
                      lineHeight="2vw"
                      textTransform="uppercase"
                    >
                      {dispLanguage === "ro" ? categ.name_ro : categ.name_en}
                    </Box>
                  </AccordionButton>
                  <AccordionPanel
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    {categories.map((subcat, index) => {
                      if (subcat.parent_id == categ.id) {
                        return (
                          <chakra.button
                            key={subcat.id}
                            onClick={() => updateCategory(subcat)}
                            padding={0}
                            textTransform="uppercase"
                            fontSize="0.950vw"
                            fontWeight={700}
                            lineHeight="1.700vw"
                            marginTop="1.500vw"
                            textAlign="left"
                            minWidth="0"
                            color={
                              category?.id === subcat.id ? "#146C78" : "#FFFFFF"
                            }
                          >
                            {dispLanguage === "ro"
                              ? subcat.name_ro
                              : subcat.name_en}
                          </chakra.button>
                        );
                      }
                    })}
                  </AccordionPanel>
                </AccordionItem>
              );
            } else if (
              categ.parent_id == null &&
              !hasChildren(categ, categories)
            ) {
              return (
                <AccordionItem border="none" marginTop="2.500vw" key={categ.id}>
                  <AccordionButton
                    onClick={() => updateCategory(categ)}
                    _expanded={{ color: "#146C78" }}
                    color="#FFFFFF"
                    padding={0}
                  >
                    <Box
                      as="span"
                      flex="1"
                      textAlign="left"
                      fontWeight={600}
                      fontSize="1.600vw"
                      lineHeight="2vw"
                      textTransform="uppercase"
                    >
                      {dispLanguage === "ro" ? categ?.name_ro : categ?.name_en}
                    </Box>
                  </AccordionButton>
                </AccordionItem>
              );
            }
            return null;
          })}
        </Accordion>
        <Link sx={styles.menu_reset} href="/" onClick={resetMenu}>
          {t("reseteaza_meniu")}
        </Link>
      </Flex>
      <Flex
        position="relative"
        direction="column"
        flex={1}
        padding="1.200vw 6vw 0 6vw"
      >
        {showScrollIndicator ? (
          <chakra.img
            src={Arrow}
            alt="scroll"
            height="2.7vw"
            width="2.7vw"
            position="absolute"
            right="35px"
            bottom="50px"
            transition="transform 0.3s ease"
            onClick={
              showScrollIndicator === "up"
                ? () => scrollTo("up")
                : () => scrollTo("down")
            }
            transform={
              showScrollIndicator === "up" ? "rotate(180deg)" : "rotate(0)"
            }
          />
        ) : null}

        {showAddNotification ||
        (showRemoveNotification && notificationProduct) ? (
          <Flex
            position="absolute"
            top="1vw"
            left="50%"
            transform="translate(-50%, 0)"
            background="#29823b"
            minWidth=" 50%"
            maxWidth="60%"
            minHeight="2.5vw"
            alignItems="center"
            padding="0.5vw 1vw"
            gap="0.5vw"
            color="#fff"
            fontWeight="600"
            fontSize="0.9vw"
            borderRadius="0.4vw"
          >
            <chakra.img height="1.25vw" src={Check} />
            <chakra.span>
              {language === "ro"
                ? notificationProduct.name_ro
                : notificationProduct.name_en}
              {showAddNotification
                ? t("notificare_adaugat")
                : t("notificare_sters")}
            </chakra.span>
          </Flex>
        ) : null}
        <chakra.h1
          fontWeight="700"
          fontSize="3vw"
          lineHeight="3.650vw"
          color="#146C78"
          marginBottom="2.150vw"
          textTransform="uppercase"
        >
          {dispLanguage === "ro" ? category?.name_ro : category?.name_en}
        </chakra.h1>
        {category?.caption_ro ? (
          <chakra.h3
            fontWeight="500"
            fontSize="1.200vw"
            lineHeight="1.450vw"
            color="#1A1C20"
            marginBottom="2.150vw"
          >
            {dispLanguage === "ro"
              ? category?.caption_ro
              : category?.caption_en}
          </chakra.h3>
        ) : null}

        <Box overflowY="scroll" ref={parent}>
          <SimpleGrid
            columns={3}
            spacingX="1.700vw"
            spacingY="2.500vw"
            paddingBottom="2.500vw"
            position="relative"
            ref={child}
          >
            {productsFromCategory
              ? productsFromCategory.map((prod) => {
                  return (
                    <ProductCard
                      product={prod}
                      category={category!}
                      weight
                      key={prod.id}
                      setNotificationProduct={setNotificationProduct}
                      setShowAddNotification={setShowAddNotification}
                      setShowRemoveNotification={setShowRemoveNotification}
                      handleModalOpen={handleModalOpen}
                      openedModal={modal === prod.id}
                    />
                  );
                })
              : null}
          </SimpleGrid>
        </Box>
      </Flex>
    </Flex>
  );
}
