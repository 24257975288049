import { useEffect, useState } from "react";
import {
  chakra,
  Flex,
  Button,
  SimpleGrid,
  Grid,
  Stack,
  Tag,
  Link,
  Wrap,
  Image,
} from "@chakra-ui/react";
import { Link as ReactLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAppStore } from "../lib/store";
import { useParams } from "react-router-dom";
import { Product } from "../lib/slices/createProductSlice";
import { Category } from "../lib/slices/createCategorySlice";
import { CompleteNutritional } from "../lib/slices/createNutritionalSlice";
import { Attribute } from "../lib/slices/createAttributeSlice";
import {
  calculateTime,
  filterAttributes,
  filterCrossSells,
  filterNutritionals,
  findInCart,
} from "../lib/helpers/products";
import ProductCard from "../components/ProductCard";
import Heart from "../img/heart.svg";
import Check from "../img/check-circle-solid.svg";
import BrokenHeart from "../img/broken-heart.png";
import LinkImg from "../img/link.png";
import Placeholder from "../img/placeholder.jpg";
import { useTranslation } from "react-i18next";
import { blurHashToDataURL } from "../blurHashDecode";

export default function Meniu() {
  const {
    language,
    products,
    categories,
    nutritionals,
    attributes,
    addToCart,
    cart,
    productsFromCategory,
    removeFromCart,
  } = useAppStore();

  const [selectedProd, setSelectedProd] = useState<Product | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const [selectedNutritionals, setSelectedNutritionals] = useState<
    CompleteNutritional[]
  >([]);

  const [showNutritional, setShowNutritional] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [showAddError, setShowAddError] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showAddNotification, setShowAddNotification] = useState(false);
  const [showRemoveNotification, setShowRemoveNotification] = useState(false);
  const [nextProduct, setNextProduct] = useState<Product | null>(null);
  const [previousProduct, setPreviousProduct] = useState<Product | null>(null);

  const [crossSells, setCrossSells] = useState<Product[]>([]);
  const [productAttributes, setProductAttributes] = useState<Attribute[]>([]);

  const { id, cat } = useParams();
  const { i18n, t } = useTranslation();

  const {
    register,
    handleSubmit,
    trigger,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    const product = products.filter(
      (product: Product) => product.id === parseInt(id ?? "0")
    );
    if (product.length) {
      setSelectedProd(product[0]);
      const result = filterCrossSells(products, product[0]);
      const nutritionalSearch = filterNutritionals(nutritionals, product[0]);
      const attributeSearch = filterAttributes(attributes, product[0]);
      setSelectedNutritionals(nutritionalSearch);
      setCrossSells(result);
      setProductAttributes(attributeSearch);
    }
    const category = categories.filter(
      (category) => category.id === parseInt(cat ?? "0")
    );

    if (category.length) {
      setSelectedCategory(category[0]);
    }
    setShowAddNotification(false);
    setShowRemoveNotification(false);
  }, [id, cat]);

  useEffect(() => {
    if (selectedProd) {
      const found = findInCart(cart, selectedProd);
      if (found) {
        setShowRemove(true);
        setSelectedProd(found);
      } else {
        setShowRemove(false);
      }
    }
    reset();
  }, [selectedProd, cart.length]);

  useEffect(() => {
    if (selectedProd) {
      const attributeSearch = filterAttributes(attributes, selectedProd);
      setProductAttributes(attributeSearch);
    }

    setShowNutritional(false);
    setShowOptions(false);
  }, [selectedProd]);

  const onSubmit = (data: any) => {
    if (data.product) {
      let attrs = data.product.filter(function (e: any) {
        return e;
      });
      attrs = attrs.map((attr: any) =>
        attributes.find((e: any) => e.id == attr.attributes)
      );
      const newProd: Product = {
        ...selectedProd!,
        attributes: attrs,
      };
      if (showRemove) {
        removeFromCart(selectedProd!.id);
      }
      setSelectedProd(newProd);
      setShowRemoveNotification(false);
      setShowAddNotification(true);
      setTimeout(() => {
        setShowAddNotification(false);
      }, 5000);
      addToCart(newProd);
    }
  };

  const handleOrderClick = () => {
    if (showRemove) {
      reset();
      removeFromCart(selectedProd!.id);
      setShowAddNotification(false);
      setShowRemoveNotification(true);
      setTimeout(() => {
        setShowRemoveNotification(false);
      }, 5000);
    } else if (productAttributes.length > 0) {
      handleSubmit((data: any) => onSubmit(data));
      // setShowAddError(true);
      // setTimeout(() => {
      //   setShowAddError(false);
      // }, 2000);
    } else {
      setShowRemoveNotification(false);
      setShowAddNotification(true);
      setTimeout(() => {
        setShowAddNotification(false);
      }, 5000);
      addToCart(selectedProd!);
    }
  };

  const toggleForm = () => {
    setShowOptions(true);
    setShowAddError(false);
  };

  const buttonActionCheck = () => {
    if (productAttributes.length && showOptions && showRemove) {
      return handleSubmit((data: any) => onSubmit(data))();
    }
    if (productAttributes.length && !showOptions && !showRemove) {
      return setShowAddError(true);
    }
    if (productAttributes.length && !showRemove) {
      return handleSubmit((data: any) => onSubmit(data))();
    } else {
      return handleOrderClick();
    }
  };

  const buttonActionText = () => {
    if (showRemove && showOptions) {
      return (
        <>
          <chakra.img
            src={Heart}
            alt="favourites"
            height="1.350vw"
            width="1.500vw"
          />
          {t("buton_salveaza_optiuni")}
        </>
      );
    } else if (showRemove && !showOptions) {
      return (
        <>
          <chakra.img
            src={BrokenHeart}
            alt="favourites"
            height="1.350vw"
            width="1.500vw"
          />
          {t("buton_elimina_comanda")}
        </>
      );
    } else {
      return (
        <>
          <chakra.img
            src={Heart}
            alt="favourites"
            height="1.350vw"
            width="1.500vw"
          />
          {t("buton_adauga_comanda")}
        </>
      );
    }
  };

  const checkIfDisabled = () => {
    if (showRemove) {
      return false;
    }
    if (productAttributes.length && showOptions) {
      return false;
    } else if (productAttributes.length && !showOptions) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (selectedProd) {
      const currentProduct = productsFromCategory.findIndex(
        (prod: Product) => prod.id === selectedProd!.id
      );
      if (currentProduct >= 1) {
        const prev = productsFromCategory[currentProduct - 1];
        if (prev && (prev.disabled || calculateTime(prev))) {
          setPreviousProduct(null);
        } else {
          setPreviousProduct(prev);
        }
      } else {
        setPreviousProduct(null);
      }
      if (currentProduct < productsFromCategory.length) {
        const next = productsFromCategory[currentProduct + 1];
        if (next && (next.disabled || calculateTime(next))) {
          setNextProduct(null);
        } else {
          setNextProduct(next);
        }
      } else {
        setNextProduct(null);
      }
    }
  }, [watch, selectedProd]);

  return (
    <>
      {selectedProd && selectedCategory ? (
        <Grid
          gridTemplateAreas='"navigation cart"
                              "thumbnail content"'
          gridTemplateColumns="repeat(2, 1fr)"
          gridTemplateRows="max-content calc(100% - 9vw)"
          padding="4vw 2vw 0"
          columnGap="2.500vw"
          rowGap="3.100vw"
          height="100%"
          maxHeight="100%"
        >
          <Flex gridArea="navigation" justifyContent="space-between">
            {showAddNotification || showRemoveNotification ? (
              <Flex
                position="absolute"
                top="1vw"
                left="50%"
                transform="translate(-50%, 0)"
                background="#29823b"
                minWidth="50%"
                maxWidth="60%"
                minHeight="2.5vw"
                alignItems="center"
                padding="0.5vw 1vw"
                gap="0.5vw"
                color="#fff"
                fontWeight="600"
                fontSize="0.9vw"
                borderRadius="0.4vw"
                zIndex="600"
              >
                <chakra.img height="1.25vw" src={Check} />
                <chakra.span>
                  {language === "ro"
                    ? selectedProd.name_ro
                    : selectedProd.name_en}
                  {showAddNotification
                    ? t("notificare_adaugat")
                    : t("notificare_sters")}
                </chakra.span>
              </Flex>
            ) : null}

            <Link
              as={ReactLink}
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap="0.500vw"
              fontWeight="600"
              fontSize="0.750vw"
              lineHeight="0.900vw"
              color="#fff"
              background="#313232"
              borderRadius="1.125vw"
              width="fit-content"
              textTransform="uppercase"
              padding="1vw"
              height="2.850vw"
              to={`/menu/${selectedCategory.id}`}
            >
              {t("link_inapoi")}
              {language === "ro"
                ? selectedCategory.name_ro
                : selectedCategory.name_en}
            </Link>
            <Flex gap="1vw">
              <Link
                as={ReactLink}
                visibility={previousProduct ? "visible" : "hidden"}
                pointerEvents={previousProduct ? "all" : "none"}
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="0.500vw"
                fontWeight="600"
                fontSize="0.750vw"
                lineHeight="0.900vw"
                color="#fff"
                background="#313232"
                borderRadius="1.125vw"
                width="fit-content"
                textTransform="uppercase"
                padding="1vw"
                height="2.850vw"
                to={`/product/${
                  previousProduct ? previousProduct.id : 0
                }/${cat}`}
              >
                {t("link_produs_anterior")}
              </Link>
              <Link
                as={ReactLink}
                visibility={nextProduct ? "visible" : "hidden"}
                pointerEvents={nextProduct ? "all" : "none"}
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="0.500vw"
                fontWeight="600"
                fontSize="0.750vw"
                lineHeight="0.900vw"
                color="#fff"
                background="#313232"
                borderRadius="1.125vw"
                width="fit-content"
                textTransform="uppercase"
                padding="1vw"
                height="2.850vw"
                to={`/product/${nextProduct ? nextProduct.id : 0}/${cat}`}
              >
                {t("link_produs_urmator")}
              </Link>
            </Flex>
          </Flex>
          <Link
            as={ReactLink}
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="0.500vw"
            fontWeight="600"
            fontSize="0.825vw"
            lineHeight="1.200vw"
            color="#fff"
            background="#313232"
            borderRadius="1.125vw"
            width="fit-content"
            gridArea="cart"
            justifySelf="self-end"
            padding="0.750vw"
            height="2.850vw"
            to="/order"
            state={{
              link: `/product/${selectedProd.id}/${cat}`,
              name:
                language === "ro"
                  ? selectedProd?.name_ro
                  : selectedProd?.name_en,
            }}
            textTransform="uppercase"
          >
            <chakra.img
              src={Heart}
              alt="favourites"
              height="1.350vw"
              width="1.500vw"
            />
            {t("link_comanda_mea")}
            <chakra.span
              boxShadow="inset 1.5px 1.5px 4.5px rgba(255, 255, 255, 0.25)"
              borderRadius="50%"
              width="1.500vw"
              height="1.500vw"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {cart.length}
            </chakra.span>
          </Link>
          <Image
            src={"https://dashboard.eker.ro/images/" + selectedProd.thumbnail}
            gridArea="thumbnail"
            maxWidth="100%"
            borderRadius="1.125vw"
            fallbackSrc={
              selectedProd.blurhash
                ? blurHashToDataURL(selectedProd.blurhash)
                : Placeholder
            }
          />
          <Flex
            gridArea="content"
            flexDirection="column"
            position="relative"
            height="auto"
            overflowY={showNutritional ? "hidden" : "scroll"}
          >
            {showNutritional ? (
              <Flex
                direction="column"
                background="rgba(49, 50, 50, 0.95)"
                borderRadius="1.125vw"
                padding="2.500vw 1.500vw"
                color="#FFFFFF"
                position="absolute"
                top="0"
                left="0"
                zIndex="1000"
                width="100%"
                overflowY="scroll"
              >
                <Button
                  background="#fff"
                  width="1.900vw"
                  height="1.900vw"
                  borderRadius="50%"
                  fontWeight="700"
                  fontSize="1.100vw"
                  lineHeight="1.900vw"
                  textAlign="center"
                  color="#313232"
                  onClick={() => setShowNutritional(false)}
                  position="absolute"
                  top="0.400vw"
                  right="0.400vw"
                  padding="0"
                  minWidth="0"
                >
                  x
                </Button>
                <chakra.h3
                  fontWeight="700"
                  fontSize="1.500vw"
                  lineHeight="1.850vw"
                  marginBottom="0.950vw"
                >
                  {t("heading_alergeni")}
                </chakra.h3>
                <chakra.p
                  fontWeight="400"
                  fontSize="1.250vw"
                  lineHeight="1.650vw"
                  marginBottom="2.500vw"
                >
                  <b> {t("heading_contine")}</b>
                  {selectedProd.alergens.map(
                    (alergen, index) =>
                      (language === "ro" ? alergen.name_ro : alergen.name_en) +
                      (index === selectedProd.alergens.length - 1 ? "." : ", ")
                  )}
                  {selectedProd.alergens.map(
                    (alergen, index) =>
                      (language === "ro" ? alergen.name_ro : alergen.name_en) +
                      (index === selectedProd.alergens.length - 1 ? "." : ", ")
                  )}
                </chakra.p>
                <chakra.h3
                  fontWeight="700"
                  fontSize="1.500vw"
                  lineHeight="1.850vw"
                  marginBottom="2.500vw"
                  marginTop="auto"
                >
                  {t("heading_valor_nutritionale")}
                </chakra.h3>
                <chakra.table
                  fontWeight="600"
                  fontSize="1.125vw"
                  lineHeight="1.350vw"
                >
                  <chakra.thead textAlign="center">
                    <chakra.tr>
                      <chakra.th padding="0.750vw">{t("nutrient")}</chakra.th>
                      <chakra.th padding="0.750vw">{t("valoare")}</chakra.th>
                      <chakra.th padding="0.750vw">{t("unitate")}</chakra.th>
                    </chakra.tr>
                  </chakra.thead>
                  <chakra.tbody>
                    {selectedNutritionals.map((nutri) => {
                      return (
                        <chakra.tr>
                          <chakra.td padding="0.750vw">
                            {language === "ro" ? nutri.name_ro : nutri.name_en}
                          </chakra.td>
                          <chakra.td padding="0.750vw" textAlign="center">
                            {nutri.value_ro}
                          </chakra.td>
                          <chakra.td padding="0.750vw" textAlign="center">
                            {nutri.unit}
                          </chakra.td>
                        </chakra.tr>
                      );
                    })}
                  </chakra.tbody>
                </chakra.table>
              </Flex>
            ) : null}
            <chakra.h1
              fontWeight="700"
              fontSize="2.5rem"
              lineHeight="0.9"
              color="#146C78"
              marginBottom="1.250vw"
            >
              {language === "ro" ? selectedProd.name_ro : selectedProd.name_en}
            </chakra.h1>
            <Stack direction="row">
              {selectedProd.tags.map((tag) => (
                <Tag
                  variant="outline"
                  fontWeight="500"
                  fontSize="1.125vw"
                  lineHeight="1.350vw"
                  textAlign="center"
                  color="#000000"
                  border="1.5px solid #000000"
                  borderRadius="0.375vw"
                  padding="2px 0.500vw"
                >
                  {language === "ro" ? tag.name_ro : tag.name_en}
                </Tag>
              ))}
            </Stack>
            <chakra.p
              fontWeight="700"
              fontSize="1.500vw"
              lineHeight="1.850vw"
              color="#1A1C20"
              marginTop="0.750vw"
              marginBottom="1vw"
            >
              {selectedProd.price} RON
            </chakra.p>
            <Flex marginBottom="0.750vw" alignItems="center">
              {selectedProd.weight != 0 ? (
                <chakra.p
                  fontWeight="700"
                  fontSize="1.200vw"
                  lineHeight="1.450vw"
                  color="#1A1C20"
                >
                  {t("heading_gramaj")}
                  {selectedProd.weight}
                  {selectedProd.unit}
                </chakra.p>
              ) : null}
              {selectedProd.alergens.length ? (
                <Button
                  background="none"
                  width="fit-content"
                  fontWeight="300"
                  fontSize="1.050vw"
                  lineHeight="1.900vw"
                  textAlign="center"
                  color="#146C78"
                  onClick={() => setShowNutritional(true)}
                >
                  <chakra.img
                    src={LinkImg}
                    alt="favourites"
                    height="1.350vw"
                    width="1.500vw"
                  />
                  {t("buton_alergeni")}
                </Button>
              ) : null}
            </Flex>

            <chakra.p fontSize="1.200vw" lineHeight="1.900vw" color="#1A1C20">
              {language === "ro" ? selectedProd.desc_ro : selectedProd.desc_en}
            </chakra.p>
            {productAttributes.length ? (
              <Flex alignItems="center" gap="0.500vw">
                <Button
                  marginTop="1.500vw"
                  marginBottom="1.300vw"
                  background={showOptions ? "#146C78" : "#FFFFFF"}
                  border="3px solid #146C78"
                  borderRadius="0.375vw"
                  width="fit-content"
                  fontWeight="500"
                  fontSize="0.900vw"
                  lineHeight="1.100vw"
                  textAlign="center"
                  minWidth="0"
                  height="30px"
                  color={showOptions ? "#FFFFFF" : "#146C78"}
                  onClick={() => toggleForm()}
                  _hover={{
                    background: "#146C78",
                  }}
                >
                  {showRemove
                    ? t("buton_editeaza_optiuni")
                    : t("buton_selecteaza_optiuni")}
                </Button>
                {showRemove &&
                  selectedProd.attributes &&
                  selectedProd.attributes.length &&
                  selectedProd.attributes.map((attribute, index) => {
                    return (
                      <>
                        <chakra.span fontSize="1.050vw">
                          {language === "ro"
                            ? attribute.name_ro
                            : attribute.name_en}
                        </chakra.span>
                        {index !== selectedProd.attributes!.length - 1
                          ? " | "
                          : ""}
                      </>
                    );
                  })}
              </Flex>
            ) : null}

            {showOptions ? (
              <Flex
                direction="column"
                background="rgba(49, 50, 50, 0.9)"
                borderRadius="1.125vw"
                padding="2.500vw 1.500vw 1.250vw"
                color="#FFFFFF"
                position="relative"
                top="0"
                left="0"
                zIndex="100"
                marginBottom="1.500vw"
              >
                <Button
                  background="#fff"
                  width="1.900vw"
                  height="1.900vw"
                  borderRadius="50%"
                  fontWeight="700"
                  fontSize="1.100vw"
                  lineHeight="1.900vw"
                  textAlign="center"
                  color="#313232"
                  onClick={() => setShowOptions(false)}
                  position="absolute"
                  top="0.400vw"
                  right="0.400vw"
                  minWidth="0"
                  padding="0"
                >
                  x
                </Button>
                <chakra.h3
                  fontWeight="700"
                  fontSize="1.050vw"
                  lineHeight="1.300vw"
                  marginBottom="0.950vw"
                >
                  {t("eroare_adaugare_optiuni")}
                </chakra.h3>
                <form onSubmit={handleSubmit((data: any) => onSubmit(data))}>
                  {productAttributes.map((attribute) => {
                    return (
                      <Flex
                        className="options_wrapper"
                        alignItems="center"
                        key={attribute.id}
                      >
                        <chakra.h4
                          fontWeight="700"
                          fontSize="1.050vw"
                          lineHeight="1.300vw"
                          marginRight="1.500vw"
                          whiteSpace="nowrap"
                        >
                          {language === "ro"
                            ? attribute.name_ro
                            : attribute.name_en}
                          :
                        </chakra.h4>
                        <Wrap spacing="0.750vw">
                          {attribute.children!.map((item) => {
                            return (
                              <Flex position="relative">
                                <chakra.input
                                  {...register(
                                    `product.${attribute.id}.attributes`,
                                    {
                                      required: {
                                        value: true,
                                        message:
                                          "Trebuie sa alegeti cel putin o optiune",
                                      },
                                    }
                                  )}
                                  type="radio"
                                  value={item.id}
                                  id={`product.${attribute.id}.attributes`}
                                />
                                <chakra.label
                                  className="options_label"
                                  htmlFor={`product.${attribute.id}.attributes`}
                                >
                                  {language === "ro"
                                    ? item.name_ro
                                    : item.name_en}
                                </chakra.label>
                              </Flex>
                            );
                          })}
                        </Wrap>
                      </Flex>
                    );
                  })}
                </form>
              </Flex>
            ) : null}

            <Button
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap="0.500vw"
              fontWeight="400"
              fontSize="0.900vw"
              lineHeight="1.200vw"
              color="#fff"
              background="#146C78"
              borderRadius="0.375vw"
              width="fit-content"
              gridArea="cart"
              justifySelf="self-end"
              padding="0.400vw"
              marginBottom="1.500vw"
              onClick={buttonActionCheck}
              marginTop={productAttributes.length ? "0" : "1.500vw"}
              minWidth="0"
              height="30px"
              _hover={{
                background: "#146C78",
              }}
              // isDisabled={checkIfDisabled()}
            >
              {buttonActionText()}
            </Button>
            {showAddError || errors.product ? (
              <chakra.p
                fontWeight="700"
                fontSize="1.200vw"
                lineHeight="1.500vw"
                color="#aa0000"
                margin="0 0 1.250vw 0"
              >
                {t("eroare_adaugare_optiuni")}
              </chakra.p>
            ) : null}
            {crossSells.length ? (
              <>
                <chakra.h2
                  fontWeight="700"
                  fontSize="1.500vw"
                  lineHeight="1.850vw"
                  color="#313232"
                  marginBottom="1.250vw"
                  marginTop="auto"
                >
                  {t("heading_recomandari")}
                </chakra.h2>
                <SimpleGrid
                  columns={3}
                  spacingX="1.700vw"
                  spacingY="2.500vw"
                  marginBottom="1vw"
                >
                  {crossSells.map((prod) => {
                    return (
                      <>
                        <ProductCard
                          product={prod}
                          category={prod.categories[0]}
                          key={prod.id}
                        />
                      </>
                    );
                  })}
                </SimpleGrid>
              </>
            ) : null}
          </Flex>
        </Grid>
      ) : (
        <p>{t("eroare_produs")}</p>
      )}
    </>
  );
}
