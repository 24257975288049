import { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  chakra,
  Flex,
  LinkBox,
  LinkOverlay,
  Wrap,
  Image,
} from "@chakra-ui/react";
import {
  calculateTime,
  filterAttributes,
  findInCart,
} from "../lib/helpers/products";
import { useAppStore } from "../lib/store";
import { useForm } from "react-hook-form";
import { Attribute } from "../lib/slices/createAttributeSlice";
import { Link as ReactLink } from "react-router-dom";
import { Product } from "../lib/slices/createProductSlice";
import { Category } from "../lib/slices/createCategorySlice";
import HeartHollow from "../img/hollow-heart.svg";
import Heart from "../img/heart.svg";
import Placeholder from "../img/placeholder.jpg";
import { useTranslation } from "react-i18next";
import { blurHashToDataURL } from "../blurHashDecode";

interface ProductCard {
  product: Product;
  category: Partial<Category>;
  weight?: boolean;
  setShowAddNotification?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowRemoveNotification?: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationProduct?: React.Dispatch<
    React.SetStateAction<Partial<Product>>
  >;
  handleModalOpen?: (x: number) => void;
  openedModal?: boolean;
}

export default function ProductCard({
  product,
  category,
  weight = false,
  setShowAddNotification,
  setShowRemoveNotification,
  setNotificationProduct,
  handleModalOpen,
  openedModal,
}: ProductCard) {
  const { language, attributes, addToCart, cart, removeFromCart } =
    useAppStore();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  const [inCart, setInCart] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [productAttributes, setProductAttributes] = useState<Attribute[]>([]);
  const { i18n, t } = useTranslation();
  const [isTimeDisabled, setIsTimeDisabled] = useState(false);

  useEffect(() => {
    const found = findInCart(cart, product);
    setInCart(found ? true : false);
  }, [cart.length]);

  const handleOrderClick = () => {
    if (inCart) {
      removeFromCart(product.id);
      if (
        setShowAddNotification &&
        setShowRemoveNotification &&
        setNotificationProduct
      ) {
        setNotificationProduct(product);
        setShowAddNotification(false);
        setShowRemoveNotification(true);
        setTimeout(() => {
          setShowRemoveNotification(false);
        }, 5000);
      }
    } else if (productAttributes.length > 0) {
      if (handleModalOpen) {
        handleModalOpen(product.id);
      }
      setShowOptions(true);
    } else {
      addToCart(product);
      if (
        setShowAddNotification &&
        setShowRemoveNotification &&
        setNotificationProduct
      ) {
        setNotificationProduct(product);
        setShowRemoveNotification(false);
        setShowAddNotification(true);
        setTimeout(() => {
          setShowAddNotification(false);
        }, 5000);
      }
    }
  };

  useEffect(() => {
    const attributeSearch = filterAttributes(attributes, product);
    setProductAttributes(attributeSearch);
    setIsTimeDisabled(calculateTime(product));
  }, [product, showOptions]);

  useEffect(() => {
    if (!openedModal) {
      setShowOptions(false);
    }
  }, [openedModal]);

  const onSubmit = (data: any) => {
    setShowOptions(false);
    if (handleModalOpen) {
      handleModalOpen(-1);
    }
    let attrs = data.product.filter(function (e: any) {
      return e;
    });
    attrs = attrs.map((attr: any) =>
      attributes.find((e: any) => e.id == attr.attributes)
    );
    const newProd = {
      ...product,
      attributes: attrs,
    };
    if (inCart) {
      removeFromCart(product.id);
    }
    if (
      setShowAddNotification &&
      setShowRemoveNotification &&
      setNotificationProduct
    ) {
      setNotificationProduct(product);
      setShowAddNotification(true);
      setTimeout(() => {
        setShowAddNotification(false);
      }, 5000);
    }
    addToCart(newProd);
    reset();
  };

  return (
    <>
      {showOptions ? (
        <Flex
          direction="column"
          borderRadius="1.125vw"
          color="#FFFFFF"
          marginBottom="1.500vw"
          position="fixed"
          top="50%"
          left="calc(50% + 11.600vw)"
          transform="translate(-50%, -50%)"
          zIndex="600"
          width="48.500vw"
          background="rgba(0,0,0,0.9)"
          border="1.5px solid #FFFFFF"
          padding="3.750vw 2.500vw"
        >
          <Button
            background="#fff"
            width="1.900vw"
            height="1.900vw"
            borderRadius="50%"
            fontWeight="700"
            fontSize="1.100vw"
            lineHeight="1.900vw"
            textAlign="center"
            padding="0"
            minWidth="0"
            color="#313232"
            onClick={() => {
              reset();
              setShowOptions(false);
            }}
            position="absolute"
            top="0.850vw"
            right="1.250vw"
          >
            x
          </Button>
          <chakra.h3
            fontWeight="700"
            fontSize="1.050vw"
            lineHeight="1.300vw"
            marginBottom="0.950vw"
          >
            {t("heading_adauga_optiuni")}

            {language === "ro" ? product.name_ro : product.name_en}
          </chakra.h3>
          <form onSubmit={handleSubmit((data: any) => onSubmit(data))}>
            {productAttributes.map((attribute) => {
              return (
                <Flex
                  key={attribute.id}
                  className="options_wrapper"
                  alignItems="center"
                >
                  <chakra.h4
                    fontWeight="700"
                    fontSize="1.050vw"
                    lineHeight="1.300vw"
                    marginRight="1.500vw"
                    whiteSpace="nowrap"
                  >
                    {language === "ro" ? attribute.name_ro : attribute.name_en}:
                  </chakra.h4>
                  <Wrap spacing="0.750vw">
                    {attribute.children!.map((item) => (
                      <Flex position="relative">
                        <chakra.input
                          {...register(`product.${attribute.id}.attributes`, {
                            required: true,
                          })}
                          type="radio"
                          value={item.id}
                          id={`product.${attribute.id}.attributes`}
                        />
                        <chakra.label
                          className="options_label"
                          htmlFor={`product.${attribute.id}.attributes`}
                        >
                          {language === "ro" ? item.name_ro : item.name_en}
                        </chakra.label>
                      </Flex>
                    ))}
                  </Wrap>
                </Flex>
              );
            })}
            <Flex
              marginTop="1.500vw"
              justifyContent="space-between"
              alignItems="center"
            >
              {errors.product ? (
                <chakra.p
                  fontWeight="700"
                  fontSize="1vw"
                  lineHeight="1.500vw"
                  color="#ff4f4f"
                >
                  {t("eroare_adaugare_optiuni")}
                </chakra.p>
              ) : null}
              <Button
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="0.500vw"
                fontWeight="400"
                fontSize="0.900vw"
                lineHeight="1.200vw"
                color="#fff"
                background="#146C78"
                borderRadius="0.375vw"
                width="fit-content"
                gridArea="cart"
                justifySelf="self-end"
                padding="0.600vw"
                type="submit"
                marginLeft="auto"
                minWidth="0"
                height="1.500vw"
              >
                {t("buton_salveaza_optiuni")}
              </Button>
            </Flex>
          </form>
        </Flex>
      ) : null}
      <LinkBox>
        <Card key={product.id} boxShadow="none" height="100%">
          {product.new_prod ? (
            <Flex
              position="absolute"
              top="0"
              left="0"
              alignItems="center"
              justify-content="center"
              zIndex="600"
              justifyContent="center"
              pointerEvents="none"
              width="70px"
              height="70px"
            >
              <Flex
                position="absolute"
                top="0"
                left="0"
                background="transparent"
                width="0"
                height="0"
                borderStyle="solid"
                borderWidth="70px 70px 0 0"
                borderColor="#146c78 transparent transparent transparent"
                borderTopLeftRadius="1.125vw"
                alignItems="center"
                justify-content="center"
                zIndex="600"
                justifyContent="center"
                pointerEvents="none"
              ></Flex>
              <chakra.p
                color="#fff"
                fontSize="0.9vw"
                fontWeight="600"
                textAlign="center"
                padding="20px"
                zIndex="601"
                transform="rotate(-45deg) translate(0%,-27%)"
              >
                {t("nou")}
              </chakra.p>
            </Flex>
          ) : null}
          {product.disabled ? (
            <Flex
              position="absolute"
              top="0"
              left="0"
              background="rgba(0,0,0,0.7)"
              width="100%"
              height="100%"
              borderRadius="1.125vw"
              alignItems="center"
              justify-content="center"
              zIndex="600"
              justifyContent="center"
            >
              <chakra.p
                color="#fff"
                fontSize="0.9vw"
                fontWeight="600"
                textAlign="center"
                padding="20px"
              >
                {t("notificare_dezactivat")}
              </chakra.p>
            </Flex>
          ) : null}
          {isTimeDisabled && !product.disabled ? (
            <Flex
              position="absolute"
              top="0"
              left="0"
              background="rgba(0,0,0,0.7)"
              width="100%"
              height="100%"
              borderRadius="1.125vw"
              alignItems="center"
              justify-content="center"
              zIndex="600"
              justifyContent="center"
            >
              <chakra.p
                color="#fff"
                fontSize="0.9vw"
                fontWeight="600"
                textAlign="center"
                padding="20px"
              >
                {t("notificare_dezactivat_timp")}
                {product.time_disabled_value
                  ? product.time_disabled_value
                  : "12:30"}
              </chakra.p>
            </Flex>
          ) : null}

          <CardBody
            padding={0}
            border="none"
            display="flex"
            flexDirection="column"
          >
            <Image
              borderTopLeftRadius="1.125vw"
              borderTopRightRadius="1.125vw"
              maxHeight="17vw"
              height="17vw"
              objectFit="cover"
              src={"https://dashboard.eker.ro/images/" + product.thumbnail}
              fallbackSrc={
                product.blurhash
                  ? blurHashToDataURL(product.blurhash)
                  : Placeholder
              }
            />
            {weight && inCart ? (
              <Button
                position="absolute"
                top="0.500vw"
                right="0.500vw"
                background="none"
                padding="0"
                onClick={() => handleOrderClick()}
                zIndex="500"
              >
                <chakra.img
                  src={Heart}
                  alt="favourites"
                  height="1.950vw"
                  width="2.450vw"
                />
              </Button>
            ) : null}
            {weight && !inCart ? (
              <Button
                position="absolute"
                top="0.500vw"
                right="0.500vw"
                background="none"
                padding="0"
                onClick={() => handleOrderClick()}
                zIndex="500"
                pointerEvents={
                  product.disabled || isTimeDisabled ? "none" : "all"
                }
              >
                <chakra.img
                  src={HeartHollow}
                  alt="favourites"
                  height="1.950vw"
                  width="2.450vw"
                />
              </Button>
            ) : null}

            <Flex
              direction="column"
              backgroundColor="#313232"
              borderBottomLeftRadius="1.125vw"
              borderBottomRightRadius="1.125vw"
              maxHeight="fit-content"
              padding={weight ? "1.2vw 0.8vw" : "0.8vw 0.600vw"}
              color="#fff"
              gap="0.500vw"
              flex={1}
              justifyContent="space-between"
            >
              <LinkOverlay
                as={ReactLink}
                to={`/product/${product.id}/${category.id}`}
                pointerEvents={
                  product.disabled || isTimeDisabled ? "none" : "all"
                }
              >
                <chakra.h3
                  fontWeight="600"
                  fontSize={weight ? "1.575vw" : "1.050vw"}
                  lineHeight={weight ? "1.900vw" : "1.300vw"}
                  textAlign="center"
                  color="#FFFFFF"
                  marginBottom="0.750vw"
                >
                  {language === "ro" ? product.name_ro : product.name_en}
                </chakra.h3>
              </LinkOverlay>
              <Flex
                fontWeight="400"
                fontSize="1.200vw"
                lineHeight="1.450vw"
                color="#FFFFFF"
                justifyContent="center"
                gap="0.750vw"
              >
                <chakra.p>{product.price} RON</chakra.p>
                {weight && product.weight != 0 ? (
                  <chakra.span>
                    {product.weight}
                    {product.unit}
                  </chakra.span>
                ) : null}
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </LinkBox>
    </>
  );
}
