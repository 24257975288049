import { create } from "zustand";
import { CartSlice, createCartSlice } from "./slices/createCartSlice";
import { createProductSlice, ProductSlice } from "./slices/createProductSlice";
import {
  CategorySlice,
  createCategorySlice,
} from "./slices/createCategorySlice";
import { createJSONStorage, persist } from "zustand/middleware";
import { NutritionalSlice, createNutritionalSlice } from "./slices/createNutritionalSlice";
import { AttributeSlice, createAttributeSlice } from "./slices/createAttributeSlice";
import { LanguageSlice, createLanguageSlice } from "./slices/createLanguageSlice";

type StoreState = ProductSlice & CartSlice & CategorySlice & NutritionalSlice & AttributeSlice & LanguageSlice;

export const useAppStore = create<StoreState>()(
  persist(
    (...a) => ({
      ...createProductSlice(...a),
      ...createCategorySlice(...a),
      ...createNutritionalSlice(...a),
      ...createAttributeSlice(...a),
      ...createCartSlice(...a),
      ...createLanguageSlice(...a),
    }),
    {
      name: "products-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
