import { StateCreator } from "zustand";

export interface Attribute {
    id: number;
    name_ro: string;
    name_en: string;
    parent_id: string;
    children?: Attribute[];
}

export interface AttributeSlice {
    attributes: Attribute[];
    fetchAttributes: () => void;
}

export const createAttributeSlice: StateCreator<AttributeSlice> = (set) => ({
    attributes: [],
    fetchAttributes: async () => {
        const res = await fetch('https://dashboard.eker.ro/api/attributes')
        set({ attributes: await res.json() })
    },
})