import { StateCreator } from "zustand";
import { Product } from "./createProductSlice";

export interface Category {
    id: number;
    parent_id?: number;
    name_ro: string;
    name_en: string;
    caption_ro?: string;
    caption_en?: string;
    c_order: number;
    created_at: string;
}

export interface CategorySlice {
    category: Category | undefined;
    categories: Category[];
    productsFromCategory: Product[];
    fetchCategories: () => void;
    changeCategory: (cat: Category) => void;
    fetchProductsFromCategory: (products: Product[], category: Category) => void;
}

export const createCategorySlice: StateCreator<CategorySlice> = (set, get) => ({
    category: undefined,
    chefCategory: undefined,
    categories: [],
    fetchCategories: async () => {
        const res = await fetch('https://dashboard.eker.ro/api/categories')
        const cats: Category[] = await res.json()
        set({ categories: cats, category: cats[0] })

    },
    changeCategory: async (cat) => {
        set({category: cat})
    },
    productsFromCategory: [],
    fetchProductsFromCategory: async (products: Product[], category: Category) => {
        const prod: Product[] = products.filter((prod) => (prod.categories.some((cat) => cat.id === category.id)))
        set({productsFromCategory: prod})
    },
})