import { Attribute } from "../slices/createAttributeSlice";
import { Category } from "../slices/createCategorySlice";
import {
  CompleteNutritional,
  Nutritional,
} from "../slices/createNutritionalSlice";
import { Product } from "../slices/createProductSlice";

export const filterCrossSells = (
  products: Product[],
  selectedProduct: Product
): Product[] => {
  const crossSells = selectedProduct.meta_data.filter(
    (meta) => meta.type === "cross_sells"
  );
  const fullProducts = products.filter((prod) =>
    crossSells.find((o) => parseInt(o.value_ro) === prod.id)
  );

  return fullProducts;
};

export const filterNutritionals = (
  nutritionals: Nutritional[],
  selectedProduct: Product
): CompleteNutritional[] => {
  const nutritionalData = selectedProduct.meta_data.filter(
    (meta) => meta.type === "nutritional"
  );

  nutritionals.sort((a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  });

  nutritionalData.sort((a, b) => {
    const indexA = nutritionals.indexOf(a as unknown as Nutritional);
    const indexB = nutritionals.indexOf(b as unknown as Nutritional);

    if (indexA < indexB) {
      return -1;
    }
    if (indexA > indexB) {
      return 1;
    }
    return 0;
  });

  const fullNutritional = nutritionalData.map((nutri, index) => {
    return {
      ...nutri,
      order: nutritionals[index].order,
      unit: nutritionals[index].unit,
    };
  });

  return fullNutritional;
};

export const findInCart = (cart: Product[], selectedProduct: Product): Product | null => {
  const findProduct = cart.find((p) => p.id === selectedProduct.id);
  if (findProduct) {
    return findProduct;
  }
  return null;
};

const nestItemsWithChildren = (attributes: Attribute[]): Attribute[] => {
  let tempAttributes: any = {};
  let completedAttributes: Attribute[] = [];
  attributes.forEach((attribute) => {

    const parent_id = attribute.parent_id;
    if (parent_id === undefined || parent_id === null)  {
      tempAttributes[attribute.id] = attribute;
      tempAttributes[attribute.id]['children'] = [];
    }
  });

  attributes.forEach((attribute) => {
    const parent_id = parseInt(attribute.parent_id);
    if (parent_id !== undefined && parent_id !== null) {
      if (tempAttributes[parent_id]?.hasOwnProperty('children')) {
        tempAttributes[parent_id]['children']!.push(attribute);
      }
    } 
  });


  completedAttributes = Object.values(tempAttributes);

  return completedAttributes;
};

export const filterAttributes = (
  attributes: Attribute[],
  selectedProduct: Product
): Attribute[] => {
  const selectedAttributes = selectedProduct.meta_data.filter(
    (meta) => meta.type === "attribute"
  );

  const fullAttributes = attributes.filter((attribute) =>
    selectedAttributes.find((o) => parseInt(o.value_en) === attribute.id || parseInt(o.value_ro) === attribute.id)
  );

  const nestedItems = nestItemsWithChildren(fullAttributes);

  return nestedItems;
};


export const calculateTime = (product: Product) => {
  if (product.time_disabled) {
    var time1 = product.time_disabled_value ? product.time_disabled_value : "12:30";
    const now = new Date();
    var time2 = now.getHours() + ":" + now.getMinutes();

    const date1 = new Date("2020-01-01 " + time1);
    const date2 = new Date("2020-01-01 " + time2);

    if (date1.getTime() > date2.getTime()) {
      return false;
    } else {
      return true;
    }
  }
  return false;
}

export const hasChildren = (
  category: Category,
  categories: Category[]
): boolean => {
  const childrenCategories = categories.filter(
    (cat) => cat.parent_id == category.id
  );

  if(childrenCategories.length) {
    return true
  }

  return false;
};
