import { StateCreator } from "zustand";

export interface Nutritional {
    id: number;
    name_ro: string;
    name_en: string;
    order: string;
    unit: string;
}

export interface CompleteNutritional {
    id: number;
    name_ro: string;
    name_en: string;
    value_en: string;
    value_ro: string;
    order: string;
    unit: string
    product_id: number;
}

export interface NutritionalSlice {
    nutritionals: Nutritional[];
    fetchNutritionals: () => void;
}

export const createNutritionalSlice: StateCreator<NutritionalSlice> = (set) => ({
    nutritionals: [],
    fetchNutritionals: async () => {
        const res = await fetch('https://dashboard.eker.ro/api/nutritionals')
        set({ nutritionals: await res.json() })
    },
})