import { StateCreator } from "zustand";
import { Attribute } from "./createAttributeSlice";

export interface Product {
    categories: {
        id: number;
        parent_id?: number;
        name_ro: string;
        name_en: string;
    }[];
    
    name_ro: string;
    name_en: string;
    desc_ro: string;
    desc_en: string;
    id: number;
    thumbnail: string;
    blurhash: string;
    price: string;
    weight: number;
    unit: string;
    disabled: number;
    new_prod: number;
    time_disabled: number;
    time_disabled_value: string;
    quantity: number;
    alergens: {
        id: number;
        name_ro: string;
        name_en: string;
    }[];
    meta_data: {
        id: number;
        product_id: number;
        type: string;
        name_ro: string;
        name_en: string;
        value_ro: string;
        value_en: string;
    }[];
    tags: {
        id: number;
        name_ro: string;
        name_en: string;
    }[];
    attributes?: Attribute[];
}

export interface ProductSlice {
    products: Product[];
    fetchProducts: () => Promise<Product[]>;
}

export const createProductSlice: StateCreator<ProductSlice> = (set) => ({
    products: [],
    fetchProducts: async () => {
        const res = await fetch('https://dashboard.eker.ro/api/products')
        let products: Product[] = await res.json()
        products = products.sort((a, b) => parseFloat(b.price) - parseFloat(a.price))
        set({ products })
        return products
    },
})