import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "../lib/store";

export default function InactivityDetector() {
  const pathname = window.location.href;
  let timeout: NodeJS.Timeout | null = null;

  const navigate = useNavigate();

  const { categories, changeCategory, resetCart } = useAppStore();

  const whitelist = ["/"];

  const restartAutoReset = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      resetCart();
      changeCategory(categories[0]);
      navigate("/", { replace: true });
    }, 1000 * 60 * 20); // 20 minute
  };

  const onMouseClick = () => {
    restartAutoReset();
  };

  useEffect(() => {
    let preventReset = false;
    for (const path of whitelist) {
      if (path === pathname) {
        preventReset = true;
      }
    }
    if (preventReset) {
      return;
    }

    restartAutoReset();

    window.addEventListener("click", onMouseClick);
  }, [pathname]);

  return <div />;
}
