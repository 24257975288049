import { useEffect, useState } from "react";
import {
  chakra,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Button,
  Link,
} from "@chakra-ui/react";
import { useAppStore } from "../lib/store";
import { Product } from "../lib/slices/createProductSlice";
import Heart from "../img/heart.png";
import Trash from "../img/trash.png";
import { Link as ReactLink, useLocation } from "react-router-dom";
import { Category } from "../lib/slices/createCategorySlice";
import { useTranslation } from "react-i18next";
import { hasChildren } from "../lib/helpers/products";

export default function Order() {
  const {
    language,
    categories,
    cart,
    totalCart,
    updateQuantity,
    changeCategory,
    resetCart,
  } = useAppStore();
  const [orderLength, setOrderLength] = useState(0);
  const [cartTotal, setCartTotal] = useState(0);
  const [showReset, setShowReset] = useState(false);
  const [orderItems, setOrderItems] = useState<Product[]>([]);
  const [categoriesList, setCategoriesList] = useState<Category[]>([]);

  const { i18n, t } = useTranslation();
  let previous = useLocation();

  useEffect(() => {
    setOrderItems(cart);
    if (!showReset) {
      const total = totalCart();
      setCartTotal(total);
    }
  });

  useEffect(() => {
    setCategoriesList(categories);
  }, [categories]);

  useEffect(() => {
    setOrderLength(cart.length);
  }, [cart.length]);

  const handleResetCart = () => {
    setShowReset(true);
    resetCart();
  };

  const resetMenu = () => {
    resetCart();
    changeCategory(categories[0]);
  };

  const styles = {
    page: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
    },
    wrapper: {
      display: "flex",
      flexDirection: "column",
      width: "21.6vw",
      maxWidth: "21.6vw",
      minWidth: "21.6vw",
      borderRight: "6px solid #146C78",
      backgroundColor: "#313232",
      color: "#fff",
      padding: "3.500vw 2.350vw",
      overflowY: "scroll",
    },
    order_button: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "0.500vw",
      fontWeight: "600",
      fontSize: "0.975vw",
      lineHeight: "1.200vw",
      color: "#313232",
      background: "#FFFFFF",
      borderRadius: "1.125vw",
      width: "fit-content",
      minWidth: "0",
      height: "2vw",
      padding: "0 0.800vw",
      textTransform: "uppercase",
    },
    order_length: {
      boxShadow: "inset 1.5px 1.5px 4.5px rgba(0, 0, 0, 0.25)",
      borderRadius: "50%",
      width: "1.500vw",
      height: "1.500vw",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    cat_title: {
      flex: "1",
      textAlign: "left",
      fontWeight: 600,
      fontSize: "1.600vw",
      lineHeight: "2vw",
      textTransform: "uppercase",
    },
    cat_link: {
      padding: 0,
      textTransform: "uppercase",
      fontSize: "0.950vw",
      fontWeight: 700,
      lineHeight: "1.700vw",
      marginTop: "1.500vw",
      color: "#FFFFFF",
    },
    order_delete: {
      background: "#FFFFFF",
      boxShadow: "3px 3px 6px 1.5px rgba(0, 0, 0, 0.25)",
      borderRadius: "1.125vw",
      fontWeight: "700",
      fontSize: "0.750vw",
      lineHeight: "0.800vw",
      color: "#FF0000",
      padding: "1vw",
      width: "fit-content",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "0.500vw",
      marginBottom: "5vw",
      marginTop: "auto",
      textTransform: "uppercase",
    },
    menu_reset: {
      background: "#FFFFFF",
      boxShadow: "3px 3px 6px 1.5px rgba(0, 0, 0, 0.25)",
      borderRadius: "1.125vw",
      fontWeight: "700",
      fontSize: "0.750vw",
      lineHeight: "0.800vw",
      color: "#FF0000",
      padding: "1vw",
      width: "fit-content",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "0.500vw",
      textTransform: "uppercase",
      marginTop: "3.5vw",
    },
  };

  const noCart = () => {
    return showReset ? (
      <Flex margin="7.400vw 3vw" direction="column">
        <chakra.p
          fontWeight="700"
          fontSize="1.500vw"
          lineHeight="1.850vw"
          color="#313232"
        >
          {t("notificare_stergere_comanda")}
        </chakra.p>
        <Link
          sx={styles.menu_reset}
          marginTop="2.500vw"
          href="/"
          onClick={resetMenu}
        >
          {t("reseteaza_meniu")}
        </Link>
      </Flex>
    ) : (
      <chakra.p
        fontWeight="700"
        fontSize="1.500vw"
        lineHeight="1.850vw"
        color="#313232"
        margin="7.400vw 3vw"
      >
        {t("notificare_comanda")}
        <chakra.img
          className="inline-heart"
          src={Heart}
          alt="favourites"
          height="1.350vw"
          width="1.500vw"
        />
        {t("adauga_la_comanda")}
      </chakra.p>
    );
  };

  return (
    <Flex sx={styles.page}>
      <Flex __css={styles.wrapper}>
        <Button __css={styles.order_button}>
          <chakra.img
            src={Heart}
            alt="favourites"
            height="1.350vw"
            width="1.500vw"
          />
          {t("link_comanda_mea")}
          <chakra.span sx={styles.order_length}>{orderLength}</chakra.span>
        </Button>
        <Accordion allowToggle defaultIndex={0}>
          {categoriesList &&
            categoriesList.map((category) => {
              if (
                category.parent_id == null &&
                hasChildren(category, categories)
              ) {
                return (
                  <AccordionItem
                    key={category.id}
                    border="none"
                    marginTop="2.500vw"
                  >
                    <AccordionButton
                      outline="none"
                      _expanded={{ color: "#146C78" }}
                      color="#FFFFFF"
                      padding={0}
                    >
                      <Box as="span" sx={styles.cat_title}>
                        {language === "ro"
                          ? category.name_ro
                          : category.name_en}
                      </Box>
                    </AccordionButton>
                    <AccordionPanel
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                    >
                      {categoriesList.map((subcat) => {
                        if (subcat.parent_id == category.id) {
                          return (
                            <Link
                              as={ReactLink}
                              key={subcat.id}
                              sx={styles.cat_link}
                              to={`/menu/${subcat.id}`}
                            >
                              {language === "ro"
                                ? subcat.name_ro
                                : subcat.name_en}
                            </Link>
                          );
                        }
                      })}
                    </AccordionPanel>
                  </AccordionItem>
                );
              } else if (
                category.parent_id == null &&
                !hasChildren(category, categories)
              ) {
                return (
                  <AccordionItem
                    border="none"
                    marginTop="2.500vw"
                    key={category.id}
                  >
                    <AccordionButton
                      _expanded={{ color: "#146C78" }}
                      color="#FFFFFF"
                      padding={0}
                    >
                      <Box as="span" sx={styles.cat_title}>
                        <Link
                          as={ReactLink}
                          key={category?.id}
                          to={`/menu/${category.id}`}
                        >
                          {language === "ro"
                            ? category?.name_ro
                            : category?.name_en}
                        </Link>
                      </Box>
                    </AccordionButton>
                  </AccordionItem>
                );
              }
              return null;
            })}
        </Accordion>
        <Link
          sx={styles.menu_reset}
          margin="auto auto 0"
          marginTop="auto !important"
          href="/"
          onClick={resetMenu}
        >
          {t("reseteaza_meniu")}
        </Link>
      </Flex>
      {orderItems.length ? (
        <Flex direction="column" flex={1} padding="1.200vw 6vw 0 6vw">
          <Flex direction="column" height="100%">
            <Link
              as={ReactLink}
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap="0.500vw"
              fontWeight="600"
              fontSize="0.750vw"
              lineHeight="0.900vw"
              color="#fff"
              background="#313232"
              borderRadius="1.125vw"
              width="fit-content"
              textTransform="uppercase"
              padding="1vw"
              height="2.850vw"
              to={
                previous.state && previous.state.link
                  ? previous.state.link
                  : "/menu"
              }
              marginLeft="auto"
            >
              {t("link_inapoi")}
              {previous.state && previous.state.name
                ? previous.state.name
                : "meniu"}
            </Link>
            <chakra.h1
              fontWeight="700"
              fontSize="1.950vw"
              lineHeight="2.400vw"
              color="#146C78"
              textAlign="center"
              marginTop="0.400vw"
              marginBottom="2.850vw"
              textTransform="uppercase"
            >
              {t("heading_comanda_mea")}
            </chakra.h1>
            <chakra.table width="full">
              <chakra.tbody>
                <chakra.tr
                  fontWeight="600"
                  fontSize="1.950vw"
                  lineHeight="2.400vw"
                  textTransform="uppercase"
                  marginBottom="2vw"
                >
                  <chakra.td></chakra.td>
                  <chakra.td
                    paddingLeft="7.400vw"
                    borderBottom="1.5px solid #000000"
                  >
                    {t("produs")}
                  </chakra.td>
                  <chakra.td
                    borderBottom="1.5px solid #000000"
                    textAlign="right"
                    paddingRight="9vw"
                  >
                    {t("pret")}
                  </chakra.td>
                </chakra.tr>
                {orderItems
                  ? orderItems.map((item) => {
                      return (
                        <chakra.tr>
                          <chakra.td width="6vw">
                            <Flex gap="3px" margin="1vw 0">
                              <Button
                                width="1.875vw"
                                minWidth="0"
                                height=" 1.875vw"
                                background="#FFFFFF"
                                boxShadow="0px 1.5px 1.5px rgba(0, 0, 0, 0.25)"
                                borderRadius="0.375vw"
                                fontWeight="500"
                                fontSize="1.500vw"
                                lineHeight="1.850vw"
                                display="flex"
                                alignItems="center"
                                textAlign="center"
                                padding="0"
                                onClick={() =>
                                  updateQuantity(item.id, "decrease")
                                }
                              >
                                -
                              </Button>
                              <chakra.span
                                fontWeight="500"
                                fontSize="1.500vw"
                                lineHeight="1.850vw"
                                display="flex"
                                alignItems="center"
                                textAlign="center"
                                color="#000000"
                                background="#FFFFFF"
                                boxShadow="inset 1.5px 1.5px 3px rgba(0, 0, 0, 0.25)"
                                borderRadius="0.375vw"
                                width="1.875vw"
                                height="1.875vw"
                                justifyContent="center"
                              >
                                {item.quantity}
                              </chakra.span>
                              <Button
                                width="1.875vw"
                                minWidth="0"
                                height=" 1.875vw"
                                background="#FFFFFF"
                                boxShadow="0px 1.5px 1.5px rgba(0, 0, 0, 0.25)"
                                borderRadius="0.375vw"
                                fontWeight="500"
                                fontSize="1.500vw"
                                lineHeight="1.850vw"
                                display="flex"
                                alignItems="center"
                                textAlign="center"
                                padding="0"
                                onClick={() =>
                                  updateQuantity(item.id, "increase")
                                }
                              >
                                +
                              </Button>
                            </Flex>
                          </chakra.td>
                          <chakra.td
                            fontWeight="600"
                            fontSize="1.500vw"
                            lineHeight="1.850vw"
                          >
                            <Flex
                              direction="column"
                              paddingLeft="3vw"
                              margin="1vw 0"
                            >
                              <h4>
                                {language === "ro"
                                  ? item.name_ro
                                  : item.name_en}
                              </h4>
                              <chakra.span
                                fontWeight="500"
                                fontSize="0.950vw"
                                lineHeight="0.900vw"
                              >
                                {item.attributes
                                  ? item.attributes.map((attr, index) => {
                                      return `${
                                        language === "ro"
                                          ? attr.name_ro
                                          : attr.name_en
                                      } ${
                                        index === item.attributes!.length - 1
                                          ? ""
                                          : " | "
                                      }`;
                                    })
                                  : null}
                              </chakra.span>
                            </Flex>
                          </chakra.td>
                          <chakra.td
                            fontWeight="500"
                            fontSize="1.500vw"
                            lineHeight="1.850vw"
                            paddingRight="7.500vw"
                            textAlign="right"
                          >
                            <chakra.span margin="1vw 0" display="block">
                              {(item.quantity * parseFloat(item.price)).toFixed(
                                2
                              )}{" "}
                              RON
                            </chakra.span>
                          </chakra.td>
                        </chakra.tr>
                      );
                    })
                  : null}
                <chakra.tr>
                  <chakra.td></chakra.td>
                  <chakra.td borderTop="1.5px solid #000000"></chakra.td>
                  <chakra.td
                    borderTop="1.5px solid #000000"
                    fontWeight="700"
                    fontSize="1.500vw"
                    lineHeight="1.850vw"
                    color="#000000"
                    paddingTop="1.500vw"
                    textAlign="right"
                    paddingRight="7.500vw"
                  >
                    {t("total")}
                    <chakra.span>{` ${cartTotal.toFixed(2)} RON`}</chakra.span>
                  </chakra.td>
                </chakra.tr>
              </chakra.tbody>
            </chakra.table>
            <Button sx={styles.order_delete} onClick={() => handleResetCart()}>
              <chakra.img src={Trash} alt="favourites" width="0.900vw" />
              {t("sterge_comanda")}
            </Button>
          </Flex>
        </Flex>
      ) : (
        noCart()
      )}
    </Flex>
  );
}
