import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "ro",
  lng: "ro",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    ro: {
      translation: {
        buton_start: "Deschide meniul",
        buton_limba: "Switch to ",
        link_inapoi: "< Înapoi la ",
        link_produs_anterior: "< Produsul anterior",
        link_produs_urmator: "Produsul următor >",
        link_comanda_mea: "comanda mea",
        heading_comanda_mea: "comanda mea",
        heading_alergeni: "Alergeni: ",
        heading_contine: "Conţine: ",
        heading_valor_nutritionale: "Valori nutriţionale: ",
        nutrient: "Nutrient",
        valoare: "Valoare",
        unitate: "Unitate măsură",
        heading_gramaj: "Gramaj: ",
        buton_alergeni: "Alergeni / Valori nutriționale",
        eroare_adaugare_optiuni:
          "Te rugăm să selectezi o opțiune din fiecare categorie.",
        heading_adauga_optiuni:
          "Te rugăm să selectezi opțiunile necesare pentru preparatul ",
        buton_elimina_comanda: "Elimină din comanda mea",
        buton_adauga_comanda: "Adaugă la comanda mea",
        buton_salveaza_optiuni: "Salvează opţiunile",
        buton_selecteaza_optiuni: "Selectează opţiuni",
        buton_editeaza_optiuni: "Editează opţiuni",
        heading_recomandari: "S-ar putea să iţi placă şi...",
        eroare_produs: "Produsul nu a fost găsit",
        sterge_comanda: "Şterge comanda",
        total: "Total: ",
        pret: "Preţ",
        produs: "Produs",
        notificare_comanda:
          "Pentru a vedea un sumar al comenzii tale, te rugăm să adaugi cel puțin un produs folosind butonul ",
        adauga_la_comanda: " Adaugă la comandă.",
        reseteaza_meniu: "Resetează meniu",
        notificare_stergere_comanda: "Comanda a fost ștearsă cu succes!",
        notificare_dezactivat_timp:
          "Acest produs nu poate fi comandat după ora ",
        notificare_dezactivat:
          "Acest produs nu poate fi comandat în acest moment.",
        notificare_adaugat: " a fost adaugat cu succes în Comanda Mea",
        notificare_sters: " a fost eliminat din Comanda Mea",
        nou: "NOU",
      },
    },
    en: {
      translation: {
        buton_start: "Open the menu",
        buton_limba: "Schimbă în ",
        link_inapoi: "< Back to ",
        link_produs_anterior: "< Previous product",
        link_produs_urmator: "Next product >",
        link_comanda_mea: "my order",
        heading_comanda_mea: "my order",
        heading_alergeni: "Allergens: ",
        heading_contine: "Contains: ",
        heading_valor_nutritionale: "Nutritional values: ",
        nutrient: "Nutrient",
        valoare: "Value",
        unitate: "Measurement unit",
        heading_gramaj: "Grammage: ",
        buton_alergeni: "Allergens / Nutritional values",
        eroare_adaugare_optiuni: "Please select 1 option from each category.",
        heading_adauga_optiuni: "Please select the options required for ",
        buton_elimina_comanda: "Remove from my order",
        buton_adauga_comanda: "Add to my order",
        buton_salveaza_optiuni: "Save options",
        buton_selecteaza_optiuni: "Select options",
        buton_editeaza_optiuni: "Edit options",
        heading_recomandari: "You might also like...",
        eroare_produs: "Product not found",
        sterge_comanda: "Delete order",
        total: "Total: ",
        pret: "Price",
        produs: "Product",
        notificare_comanda:
          "To be able to see your order please select at least one product using the ",
        adauga_la_comanda: " Add to my order button.",
        reseteaza_meniu: "Reset menu",
        notificare_stergere_comanda:
          "Your order has been successfully deleted!",
        notificare_dezactivat_timp: "This product cannot be ordered after ",
        notificare_dezactivat: "This product cannot be ordered at this time.",
        notificare_adaugat: " has been successfully added to My Order",
        notificare_sters: " has been removed from My Order",
        nou: "NEW",
      },
    },
  },
});

export default i18n;
