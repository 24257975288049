import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Splashscreen from "./pages/Splashscreen";
import Menu from "./pages/Menu";
import Product from "./pages/Product";
import Order from "./pages/Order";
import InactivityDetector from "./components/InactivityDetector";

function App() {
  return (
    <Router>
      <InactivityDetector />
      <Routes>
        <Route path="/" element={<Splashscreen />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/menu/:cat" element={<Menu />} />
        <Route path="/product/:id/:cat" element={<Product />} />
        <Route path="/order" element={<Order />} />
      </Routes>
    </Router>
  );
}

export default App;
