import { StateCreator } from "zustand";

export interface LanguageSlice {
    language: string;
    switchLanguage: (language: string) => void;
}

export const createLanguageSlice: StateCreator<LanguageSlice> = (set, get) => ({
    language: 'ro',
    switchLanguage: async (language) => {
        set({language})
    },
})