import { Flex, chakra } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppStore } from "../lib/store";
import FlagRo from "../img/ro.png";
import FlagEn from "../img/en.png";
import Video from "../img/loop.webm";
import { useTranslation } from "react-i18next";
import useRunOnce from "../lib/helpers/useRunOnce";
import ClipLoader from "react-spinners/ClipLoader";

const Splashscreen = () => {
  const {
    language,
    fetchProducts,
    fetchCategories,
    fetchNutritionals,
    fetchAttributes,
    switchLanguage,
  } = useAppStore();
  const { i18n, t } = useTranslation();

  useEffect(() => {
    fetchCategories();
    fetchNutritionals();
    fetchAttributes();
    switchLanguage("ro");
    i18n.changeLanguage("ro");
  }, []);

  const [loading, setLoading] = useState(false);

  const changeLanguage = () => {
    switchLanguage(language === "ro" ? "en" : "ro");
    i18n.changeLanguage(language === "ro" ? "en" : "ro");
  };

  const cacheImages = async (images: string[]) => {
    const promises = images.map((image: string) => {
      return new Promise<any>((resolve, reject) => {
        const img = new Image();
        img.src = `https://dashboard.eker.ro/images/${image}`;
        img.onload = () => resolve(img.height);
        img.onerror = reject;
      });
    });

    await Promise.all(promises)
      .then((imgs) => {
        setLoading(false);
        console.log(imgs);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useRunOnce({
    fn: async () => {
      setLoading(true);

      const products = await fetchProducts();
      if (products) {
        const images = products.map((prod) => {
          return prod.thumbnail;
        });
        cacheImages(images);
      }
    },
    sessionKey: "ekersession",
  });

  return (
    <div className="container">
      <main className="main">
        {loading ? (
          <div className="overlay_loading">
            <Flex alignItems="center" gap="1vw">
              <ClipLoader color="#fff" loading={loading} />
              <chakra.h3 color="#fff" fontSize="1.5vw">
                Se descarca imaginile, va rugam asteptati
              </chakra.h3>
            </Flex>
          </div>
        ) : null}
        <chakra.button
          className="language_switcher"
          onClick={() => changeLanguage()}
        >
          {t("buton_limba")}
          <chakra.img
            src={language === "ro" ? FlagEn : FlagRo}
            alt="schimba limba"
            width={42}
            height={28.5}
          />
        </chakra.button>
        <video className="video_bg" autoPlay loop muted>
          <source src={Video} type="video/mp4" />
        </video>
        <Link to="/menu" className="open_menu">
          {t("buton_start")}
        </Link>
      </main>
    </div>
  );
};

export default Splashscreen;
